<template>
    <div class="com-banner-oa-component">
        <div class="block">
            <!-- <span class="demonstration">Click 指示器触发</span> -->
            <!-- :interval="5000" -->
            <el-carousel trigger="click"  height="350px">
                <el-carousel-item v-for="item in bannerData" :key="item.id">
                    <div class="swiper-img" @click="goDetail(item)" style="cursor:pointer;">
                        <img :src="item.titleImg" alt="" />
                        <h3 class="small">
                            <p>{{item.title}}</p>
                        </h3>
                    </div>

                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
import img1 from '../assets/img/book.png'
import img2 from '../assets/img/book2.png'
import img3 from '../assets/img/book3.png'
import img4 from '../assets/img/book4.png'
export default {
    props: {
        bannerData: {
            type: Array,
            default: []
        },
        pageName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    },
    mounted() {},
    methods: {
        goDetail(item) {
            // if (item.originUrl) {
            //     window.open(item.originUrl, '_blank')
            // } else {
            //     this.$router.push({path: '/nD/n', query: {id: item.id, pN: encodeURI(this.pageName)}})
            // }

            if (item.jumpUrl) {
                window.open(item.jumpUrl, '_blank')
            } else if (item.originUrl) {
                window.open(item.originUrl, '_blank')
            } else {
                this.$router.push({path: '/nD/n', query: {id: item.id, pN: encodeURI(this.pageName)}})
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.com-banner-oa-component {
    width: 690px;
    height: 350px;
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
}
</style>