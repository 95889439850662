<template>
    <div class="resource-discipline-component" id="resourceDiscipline">

    </div>
</template>

<script>
// import echarts from "echarts"
// import wordcloud from "echarts-wordcloud";

export default {
    props: ['chartData1', 'chartTotal1'],
    data() {
        return {
            chartList1: [],
            total: 0
        }
    },
    watch: {
        chartTotal1 (newVal, oldVal) {
            var x = newVal
            this.total = x.toString()
        },
        chartData1 (newVal, oldVal) {
            //console.log('newVal', newVal)
            var curA = []
            var num = 0
            var x = newVal
            x.forEach((e,ind)=> {
                
                var obj = {
                    name: e.name,
                    value: e.count
                }
                if (ind<19) {
                    curA.push(obj)
                } else {
                    num=e.count+num
                }
                
            })
            if (x.length>18) {
                var j = {
                    name: '其他',
                    value: num
                }
                curA.push(j)
            }
            
            
            this.chartList1 = curA
            this.init()
        }
    },
    mounted() {
        // this.init()
    },
    methods: {
        init() {
            // import echarts from "echarts";
            var myChart = this.$echarts.init(document.getElementById('resourceDiscipline'))
            var option = {
                color: ['#8B062A', '#DA1047', '#FF6975', '#FA8471', '#F4B301', '#7bed9f', '#2ed573', '#70a1ff',  '#49A3F5', '#D658EB'],
                // 'rgba(153, 153, 153, 0.2)', 
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    orient: 'vertical',
                    top: 'center',
                    height: '85%',
                    // left: 'right',
                    x: 292,
                    // y: 20,
                    icon: "circle",
                    itemWidth: 10,  // 设置icon宽度
                    itemHeight: 10,
                    itemGap: 18, // 设置间距
                    itemStyle: {
                        // color: '#777'
                    }
                },
                // label: {
                //     alignTo: 'edge',
                //     formatter: this.total,
                //     minMargin: 5,
                //     edgeDistance: 10,
                //     lineHeight: 15,
                //     rich: { value: { fontSize: 28, color: '#000' } }
                // },
                graphic: {
                    elements: [
                    //为环形图中间添加文字
                    {
                        type: 'text',
                        left: '18%', //'18%',
                        top: '40%',
                        style: {
                            text: this.total,// '123',
                            fontSize: 28,
                            
                            textAlign: 'center',
                            // width: 160,
                            // height: 28,
                            fill: '#000',
                            // textConfig: {
                            //     position: 'inside'
                            // },
                        },
                    },
                    {
                        type: 'text',
                        left: '17%',
                        top: '52%',
                        
                        style: {
                            text: '资源总数',
                            textAlign: 'center',
                            fill: '#999999',
                            fontSize: 16,
                        },
                    },
                ]},
                // graphic: [
                //     //为环形图中间添加文字
                //     {
                //         type: 'text',
                //         left: '18%',
                //         top: '40%',
                //         width: 100,
                //             height: 28,
                //         style: {
                //             text: this.total,// '123',
                //             fontSize: 28,
                //             textAlign: 'center',
                //             width: 100,
                //             height: 28,
                //             fill: '#000',
                //         },
                //     },
                //     {
                //         type: 'text',
                //         left: '17%',
                //         top: '52%',
                        
                //         style: {
                //             text: '资源总数',
                //             textAlign: 'center',
                //             fill: '#999999',
                //             fontSize: 16,
                //         },
                //     },
                // ],
                series: [
                    {
                        // name: 'Access From',
                        type: 'pie',
                        radius: ['45%', '77%'],
                        center: ['46%', '48%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                        },
                        top: 0,
                        left: 0,
                        right: '50%',
                        // emphasis: {
                        //     label: {
                        //         show: true,
                        //         fontSize: '40',
                        //         fontWeight: 'bold',
                        //     },
                        // },
                        labelLine: {
                            show: false,
                        },
                        data: this.chartList1
                        // [
                        //     { value: 1048, name: 'Search Engine' },
                        //     { value: 735, name: 'Direct' },
                        //     { value: 580, name: 'Email' },
                        //     { value: 484, name: 'Union Ads' },
                        //     { value: 300, name: 'Video Ads' },
                        //     { value: 280, name: 'Search Engine1' },
                        //     { value: 250, name: 'Direct2' },
                        //     { value: 230, name: 'Emai3l' },
                        //     { value: 200, name: 'Union Ads4' },
                        //     { value: 280, name: 'Video Ads5' },
                        // ],
                    },
                ],
            }
            myChart.setOption(option, true)
        }}
}
</script>

<style lang="scss" scoped>
.resource-discipline-component {
    width: 590px;
    height: 350px;
    
}
</style>