<template>
    <div class="footer-oa-component wid1200">
        <div class="product-link">
            <h5><span>产品链接</span></h5>
            <ul>
                <li><a href="#">皮书数据库</a></li>
                <li><a href="#">一带一路数据库</a></li>
                <li><a href="#">国别区域与全球治理数据平台</a></li>
                <li><a href="#">减贫研究数据库</a></li>
                <li><a href="#">京津冀协同发展数据库</a></li>
                <li><a href="#">乡村研究数据库</a></li>
                <li><a href="#">台湾大陆同乡会文献数据库</a></li>
            </ul>
        </div>
        <div class="friendly-link">
            <h5><span>友情链接</span></h5>
            <ul>
                <li><a href="#">贵州省社会科学院</a></li>
                <li><a href="#">贵州师范大学</a></li>
                <li><a href="#">中国海洋大学</a></li>
                <li><a href="#">中国佛学院</a></li>
                <li><a href="#">首都师范大学文化研究院</a></li>
                <li><a href="#">南开大学世界近代史研究中心</a></li>
                <li><a href="#">贵州省社会科学院</a></li>
                <li><a href="#">贵州师范大学</a></li>
                <li><a href="#">中国海洋大学</a></li>
                <li><a href="#">中国佛学院</a></li>
                <li><a href="#">首都师范大学文化研究院</a></li>
                <li><a href="#">南开大学世界近代史研究中心</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer-oa-component {
        h5 {
            text-align: center;
            position: relative;
            z-index: 0;
            display: flex;
            justify-content: center;
            margin-bottom: 22px;
            span {
                display: block;
                width: 100px;
                text-align: center;
                color: #2B221B;
                font-size: 18px;
                line-height: 18px;
                z-index: 2;
                background: #fff;
                font-weight: bold;
            }
        }
        h5::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 1px;
            background: #EAEFF3;
            z-index: -1;
        }
        div {
            margin-top: 30px;
            ul {
                display: flex;
                flex-wrap: wrap;
                
                li {
                    margin: 0 32px 16px 0;
                    a {
                        display: block;
                        font-size: 14px;
                        color: #777777;
                        line-height: 14px;
                    }
                }
            }
        }
    }
</style>