<template>
    <div class="output-time-component" id="outputTime">

    </div>
</template>

<script>
export default {
    props: ['chartData3'],
    data() {
        return {
            chartList3: [
            
            ],
        }
    },
    watch: {
        chartData3(newVal, oldVal) {
            var x =  []
            x = newVal
            this.chartList3 = x
            this.init()
        },
    },
    mounted() {
        // this.init()
    },
    methods: {
        init() {
            var xData = []
            var yData = []
            this.chartList3.forEach((e, ind) => {
                xData.push(e.name)
                yData.push(parseInt(e.count))
            })
            var myChart = this.$echarts.init(document.getElementById('outputTime'))
            var option = {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xData,
                    // ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                },
                yAxis: {
                    type: 'value',
                },
                tooltip: {
                    trigger: 'axis',
                },
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '6%',
                    top: '10%',
                    containLabel: true,
                },
                series: [
                    {
                        data: yData,
                        //[820, 932, 901, 934, 1290, 1330, 1320],
                        type: 'line',
                        smooth: true,
                        // symbol: 'none',
                        itemStyle: {
                            color: 'rgb(244, 179, 1)',
                        },
                        areaStyle: {
                            opacity: 0.8,
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(244, 179, 1, 0.2)',
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(255, 255, 255, 0.1)',
                                },
                            ]),
                        },
                    },
                ],
            }
            myChart.setOption(option, true)
        },
    },
}
</script>

<style lang="scss" scoped>
.output-time-component {
    width: 1198px;
    height: 360px;
    //margin-left: 25px;
}
</style>